@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  @apply m-0 h-full;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  @apply h-full;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.favicon {
  mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='138'%3E%3Csvg viewBox='0 0 138 138' xmlns='http://www.w3.org/2000/svg' xml:space='preserve' fill-rule='evenodd' clip-rule='evenodd' stroke-linejoin='round' stroke-miterlimit='2'%3E%3Cpath d='M47.97 73.38h42.05L69 106.3 47.97 73.38ZM137.9 3.5h-.37c0 17.01-21.63 30.85-38.65 30.85H97.2c-19.72.17-22.07 14.8-22.35 18.28l-5.84.75-5.84-.75c-.28-3.48-2.63-18.11-22.35-18.28h-1.69C22.12 34.35.47 20.51.47 3.5H.11c0 23.87 8.68 43.86 28.95 48.73l5.19 8.13h-12.6L69 134.5l47.34-74.14h-12.6l5.2-8.13c20.26-4.86 28.95-24.86 28.95-48.73h.01Z'/%3E%3C/svg%3E%3C/svg%3E");
  mask-size: contain;
}

.loader {
  color: inherit;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation:
    load6 1.7s infinite ease,
    round 1.7s infinite ease;
  animation:
    load6 1.7s infinite ease,
    round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
  0% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em,
      0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em,
      0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em,
      -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em,
      -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em,
      -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em,
      0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em,
      0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em,
      0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em,
      -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em,
      -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em,
      -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em,
      0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.button {
  @apply select-none bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm p-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150;
}

.button:disabled {
  @apply bg-slate-400;
}

.button.slim {
  @apply text-xs font-normal;
}

.text-input,
.virtual-select__search {
  @apply p-2 placeholder-slate-400 text-slate-600 relative bg-white border rounded text-sm shadow outline-none focus:outline-none w-full;
}

.text-input:focus,
.virtual-select__search:focus {
  @apply border-blue-500;
}

.select__control,
.virtual-select__control {
  @apply text-slate-300 relative bg-white !border-slate-300 rounded text-sm shadow-none outline-none focus:outline-none;
}

.virtual-select__search {
  @apply rounded-b-none;
}

.select__placeholder,
.virtual-select__placeholder {
  @apply !text-slate-400 text-sm;
}

.select__indicator-separator,
.virtual-select__indicator-separator {
  @apply !bg-slate-200;
}

.form-field {
  @apply flex flex-wrap;
}

.form-error {
  @apply text-red-600 bg-red-100 border border-red-200 rounded p-3 w-full block mx-auto;
}

.form-error.field-error {
  @apply border p-2 w-[calc(100%-0.5em)] block mx-auto;
}

.form-warning {
  @apply text-amber-600 bg-amber-100 border border-amber-200 rounded p-3 w-full block mx-auto;
}

.form-warning.field-warning {
  @apply border p-2 w-[calc(100%-0.5em)] block mx-auto;
}

.form-info {
  @apply text-slate-600 bg-slate-100 border border-slate-200 rounded p-3 w-full block mx-auto;
}

.form-info.field-info {
  @apply border border-t-0 rounded-t-none p-2 w-[calc(100%-0.5em)] block mx-auto;
}

.checkbox {
  @apply appearance-none checked:border-transparent checked:bg-currentColor border-0 bg-white shadow rounded text-slate-700 w-5 h-5 ease-linear transition-all duration-150 cursor-pointer;
}

.checkbox:checked {
  background-size: 100% 100%;
  background-position: 50%;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3E%3C/svg%3E");
}
